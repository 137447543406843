import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Menu = () => {
  return (
    <div className="menu">
      <Link to="/" className="nav-link">
        <div>
          <img src="icons/menu/education.svg" alt="Обучение" />
          <p className="menuButtonText">Обучение</p>
        </div>
      </Link>
      <Link to="/TrafficRules" className="nav-link">
        <div>
          <img src="icons/menu/trafficRules.svg" alt="ПДД" />
          <p className="menuButtonText">ПДД</p>
        </div>
      </Link>
      <Link to="/RoadSigns" className="nav-link">
        <div>
          <img src="icons/menu/roadSigns.svg" alt="Знаки" />
          <p className="menuButtonText">Знаки</p>
        </div>
      </Link>
      <Link to="/Settings" className="nav-link">
        <div>
          <img src="icons/menu/settings.svg" alt="Настройки" />
          <p className="menuButtonText">Настройки</p>
        </div>
      </Link>
    </div>
  );
};

export default Menu;
