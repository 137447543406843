import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Импорт Firestore из firebaseConfig
import { collection, addDoc } from 'firebase/firestore'; // Функции для работы с Firestore

const ExamPage = ({ isConfirmEnabled, isCategoryAB }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [pressedAnswer, setPressedAnswer] = useState(null);
  const [timer, setTimer] = useState(1200);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const { ticketId, topicId } = useParams();
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [currentTopicId, setCurrentTopicId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        const categoryPath = isCategoryAB ? 'A_B' : 'C_D'; // Выбор категории

        if (ticketId) {
          const ticketPath = `${process.env.PUBLIC_URL}/pdd_russia_base/questions/${categoryPath}/tickets/Bilet_${ticketId}.json`;
          const response = await fetch(ticketPath);
          data = await response.json();
          setCurrentTicketId(ticketId);
        } else if (topicId) {
          const topicPath = `${process.env.PUBLIC_URL}/pdd_russia_base/questions/${categoryPath}/topics/Topic_${topicId}.json`;
          const response = await fetch(topicPath);
          if (!response.ok) throw new Error('Network response was not ok');
          data = await response.json();
          setQuestions(data.questions);
          setCurrentTopicId(topicId);
        } else {
          const randomTicketId = Math.floor(Math.random() * 40) + 1;
          const ticketPath = `${process.env.PUBLIC_URL}/pdd_russia_base/questions/${categoryPath}/tickets/Bilet_${randomTicketId}.json`;
          const response = await fetch(ticketPath);
          data = await response.json();
          setCurrentTicketId(randomTicketId);
        }

        setQuestions(data);
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
        alert('Произошла ошибка при загрузке вопросов. Пожалуйста, попробуйте еще раз.');
      }
    };

    fetchData();
  }, [ticketId, topicId, isCategoryAB]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(timerInterval);
          const timeLeft = formattedTime();
          navigate('/training/results', {
            state: { correctAnswers, incorrectAnswers, totalQuestions: questions.length, timeLeft }
          });
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [correctAnswers, incorrectAnswers, navigate, questions.length]);

  const handleAnswerClick = (index) => {
    setSelectedAnswer(index);
    if (!isConfirmEnabled) {
      processAnswer(index);
    }
  };

  const handleConfirmClick = () => {
    if (selectedAnswer === null) {
      alert('Пожалуйста, выберите ответ.');
      return;
    }
    processAnswer(selectedAnswer);
  };

  const processAnswer = async (index) => {
    const answers = questions[currentQuestionIndex]?.answers;
    if (!answers || answers.length === 0) {
      console.error('Ответы не найдены для текущего вопроса');
      return;
    }

    const correctAnswerIndex = answers.findIndex((answer) => answer.is_correct);
    const isCorrect = index === correctAnswerIndex;

    if (isCorrect) {
      setCorrectAnswers((prev) => prev + 1);
    } else {
      setIncorrectAnswers((prev) => prev + 1);
    }

    // Добавление результата в базу данных Firebase
    try {
      const resultData = {
        questionId: currentQuestionIndex + 1,
        isCorrect: isCorrect,
        timestamp: new Date(),
      };
      await addDoc(collection(db, 'examResults'), resultData);
      console.log('Ответ успешно добавлен в базу данных');
    } catch (error) {
      console.error('Ошибка при добавлении ответа в базу данных:', error);
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
      setPressedAnswer(null);
    } else {
      navigate('/training/results', { state: { correctAnswers, incorrectAnswers, totalQuestions: questions.length, timeLeft: formattedTime() } });
    }
  };

  const formattedTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  if (!questions.length) {
    return <h2>Загрузка вопросов...</h2>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="main-container">
      <div className="examHeader">
        <Link className="closeButton" to="/">
          <img src="/icons/close.svg" alt="Close" />
        </Link>
        <p className="ticket_number smallText gray">
          {currentTicketId
            ? `Билет ${currentTicketId}, Вопрос ${currentQuestionIndex + 1} из ${questions.length}`
            : `Тема ${currentTopicId}, Вопрос ${currentQuestionIndex + 1} из ${questions.length}`}
        </p>
        <h3 id="timer">{formattedTime()}</h3>
      </div>

      <div className="questionContent">
        {currentQuestion.image && currentQuestion.image !== './images/no_image.jpg' && (
          <img
            src={`${process.env.PUBLIC_URL}/pdd_russia_base${currentQuestion.image.replace('./', '/')}`}
            alt="Question_Image"
          />
        )}
        <p className="question textStrong">{currentQuestion.question}</p>
        
        <div className="answerOptions">
          {currentQuestion.answers.map((answer, index) => (
            <div
              key={index}
              className={`answerOption ${selectedAnswer === index ? 'selected' : ''} ${pressedAnswer === index ? 'pressed' : ''}`}
              onMouseDown={() => setPressedAnswer(index)}
              onMouseUp={() => setPressedAnswer(null)}
              onClick={() => handleAnswerClick(index)}
            >
              {answer.answer_text}
            </div>
          ))}
        </div>
      </div>

      {isConfirmEnabled && (
        <div className="examButtonPad">
          <div className="ButtonsPadLine">
            <button
              id="confirmAnswerButton"
              className="confirmAnswerButton white"
              onClick={handleConfirmClick}
            >
              Подтвердить ответ
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamPage;
