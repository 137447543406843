import React from 'react';
import { Link } from 'react-router-dom';

const ExamResult = ({ incorrectAnswers, totalQuestions, timeLeft }) => {
  const isPassed = incorrectAnswers <= 2; // Условия для проверки, сдан ли экзамен

  return (
    <div className="main-container">
      <div className="examHeader">
        <Link className="closeButton" to="/">
          <img src="/icons/close.svg" alt="Close" />
        </Link>
        <h3>{timeLeft || 'Результаты теста'}</h3>
        <Link className="closeButton" to="/">
          <img src="/icons/share.svg" alt="Share" />
        </Link>
      </div>

      <div className="passed">
        <img 
          className="passedImg" 
          src={isPassed ? "/img/exam_passed/passed.svg" : "/img/exam_passed/failed.svg"} 
          alt={isPassed ? "Passed" : "Failed"} 
        />
        <div className="mistakesInfo">
          <img 
            src={isPassed ? "/img/exam_passed/exam_passed_icons.svg" : "/img/exam_passed/exam_failed_icons.svg"} 
            alt="Mistakes Info" 
          />
          <p>{`${incorrectAnswers || 0} ошибок, ${totalQuestions || 0} вопросов`}</p>
        </div>
      </div>

      <div className="examButtonPad">
        <div className="ButtonsPadLine">
          <Link to="/reset_session" className="confirmAnswerButton">
            <p className="white">Начать заново</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExamResult;