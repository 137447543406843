import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const ThemsPage = () => {
  const [themes, setThemes] = useState([]);
  const navigate = useNavigate();

  // Массив тем с id и количеством вопросов
  const themesWithIds = [
    { id: 1, name: "Общие положения", total: 25 },
    { id: 2, name: "Дорожные знаки", total: 126 },
    { id: 3, name: "Дорожная разметка", total: 40 },
    { id: 4, name: "Сигналы светофора и регулировщика", total: 39 },
    { id: 5, name: "Начало движения, маневрирование", total: 113 },
    { id: 6, name: "Скорость движения", total: 19 },
    { id: 7, name: "Обгон, опережение, встречный разъезд", total: 36 },
    { id: 8, name: "Остановка и стоянка", total: 47 },
    { id: 9, name: "Проезд перекрестков", total: 113 },
    { id: 10, name: "Пользование внешними световыми приборами и звуковыми сигналами", total: 23 },
    { id: 11, name: "Неисправности и условия допуска транспортных средств к эксплуатации", total: 26 },
    { id: 12, name: "Безопасность движения и техника управления автомобилем", total: 59 },
    { id: 13, name: "Оказание доврачебной медицинской помощи", total: 20 },
    { id: 14, name: "Общие обязанности водителей", total: 15 },
    { id: 15, name: "Расположение транспортных средств на проезжей части", total: 24 },
    { id: 16, name: "Приоритет маршрутных транспортных средств", total: 5 },
    { id: 17, name: "Буксировка механических транспортных средств", total: 8 },
    { id: 18, name: "Применение специальных сигналов", total: 9 },
    { id: 19, name: "Движение по автомагистралям", total: 13 },
    { id: 20, name: "Учебная езда и дополнительные требования к движению велосипедистов", total: 4 },
    { id: 21, name: "Движение в жилых зонах", total: 7 },
    { id: 22, name: "Движение через железнодорожные пути", total: 11 },
    { id: 23, name: "Пешеходные переходы и места остановок маршрутных транспортных средств", total: 5 },
    { id: 24, name: "Перевозка людей и грузов", total: 6 },
    { id: 25, name: "Ответственность водителя", total: 15 },
    { id: 26, name: "Применение аварийной сигнализации и знака аварийной остановки", total: 9 }
  ];

  useEffect(() => {
    setThemes(themesWithIds);
  }, []);

  const handleThemeClick = (themeId) => {
    // Переход на страницу экзамена с id темы
    navigate(`/training/exam/topic/${themeId}`);
  };
  

  return (
    <div className="main-container">
      <div className="header">
        <Link to="/" className="closeButton">
          <img src="../icons/arrowBack.svg" alt="Назад" />
        </Link>
        <h2 className="category-title">Темы</h2>
      </div>
      <p className="smallText">
        По мере обучения ваш прогресс будет заполняться. Ваша цель — все темы
        должны быть заполнены!
      </p>
      <div className="themesList">
        {themes.map((theme, index) => (
          <div
            key={index}
            className="themeItem"
            onClick={() => handleThemeClick(theme.id)}
          >
            <p className="themeName">{theme.name}</p>
            <div className="themesProgressWrapper">
              <div className="themesProgressContainer">
                <div
                  className="themesProgressBar"
                  style={{ width: `${(theme.progress / theme.total) * 100}%` }}
                />
              </div>
              <p className="themesProgressNumbers">
                {theme.progress} из {theme.total}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThemsPage;
