import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MainPage = ({ user }) => {
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [completedTickets, setCompletedTickets] = useState(0);
  const totalQuestions = 800;
  const totalTickets = 40;
  const examReadiness = Math.floor((correctAnswers / totalQuestions) * 100);

  useEffect(() => {
    const fetchProgress = async () => {
      if (user) {
        try {
          const response = await fetch(`http://5.35.28.109:3000/get-progress/${user.id}`);
          const data = await response.json();

          const progress = JSON.parse(data.progress || '{}');
          setCorrectAnswers(progress.correctAnswers || 0);
          setCompletedTickets(data.completedTickets || 0);
        } catch (error) {
          console.error('Ошибка при получении данных статистики:', error);
        }
      }
    };

    fetchProgress();
  }, [user]);

  return (
    <div className="main-container">
      <div className="rounded-rectangle" id="statistics-box">
        <img src="img/training-car.jpg" alt="car_image" id="car-image" />
        <div className="rounded-rectangle-container">
          <div>
            <div className="flex-container">
              <p className="smallText">Готовность к экзамену</p>
              <p className="smallText">{examReadiness}%</p>
            </div>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${examReadiness}%` }}></div>
            </div>
          </div>

          <div className="progress-numbers">
            <div>
              <h3>{`${correctAnswers}/${totalQuestions}`}</h3>
              <p className="smallText">пройдено вопросов</p>
            </div>
            <div>
              <h3>{`${completedTickets}/${totalTickets}`}</h3>
              <p className="smallText">билетов пройдено</p>
            </div>
          </div>
        </div>
      </div>

      <div className="twoTrainingButtons">
        <Link to="/training/Thems" className="rounded-rectangle">
          <img src="icons/tests.svg" alt="Темы" />
          <h3>Темы</h3>
        </Link>

        <Link to="/training/Tickets" className="rounded-rectangle">
          <img src="icons/tickets.svg" alt="Билет" />
          <h3>Билеты</h3>
        </Link>
      </div>

      <Link to="/training/exam" className="examButton">
        <img src="icons/exam.svg" alt="Экзамен" />
        <h3>Экзамен</h3>
      </Link>
    </div>
  );
};

export default MainPage;
