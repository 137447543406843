import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SettingsPage = ({ user, onToggleConfirm, onToggleCategory }) => {
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [isCategoryAB, setIsCategoryAB] = useState(true);

  useEffect(() => {
    const savedValue = localStorage.getItem('isConfirmEnabled');
    if (savedValue !== null) {
      setIsConfirmEnabled(JSON.parse(savedValue));
    }
  }, []);

  const handleToggleConfirm = () => {
    const newValue = !isConfirmEnabled;
    setIsConfirmEnabled(newValue);
    localStorage.setItem('isConfirmEnabled', JSON.stringify(newValue));
    onToggleConfirm(newValue);
  };

  const handleToggleCategory = () => {
    const newCategory = !isCategoryAB;
    setIsCategoryAB(newCategory);
    localStorage.setItem('isCategoryAB', JSON.stringify(newCategory));
    onToggleCategory(newCategory);
  };

  return (
    <div className="main-container">
      <div className="userInfo">
        <img 
          src={user && user.photo ? user.photo : "img/cartoonRacer.jpg"} 
          alt="userPhoto" 
          className="userPhoto" 
        />
        <h2 className='textStrong'> 
          {user ? `${user.first_name} ${user.last_name}` : 'Гость'}
        </h2>
      </div>
      <nav className="settings-menu">
        <Link className="menuSettingsText" to="/Premium"> {/* Заменил href на to */}
          <img src="icons/settingsMenu/premium.svg" alt="premium" />
          <p>Подписка Premium</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </Link>
        <Link className="menuSettingsText" to="/"> {/* Заменил href на to */}
          <img src="icons/settingsMenu/starFill.svg" alt="star" />
          <p>Избранные вопросы</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </Link>
        <div className="menuSettingsText" onClick={handleToggleCategory}>
          <img src="icons/settingsMenu/category.svg" alt="category" />
          <p>Категория обучения</p>
          <img src={`icons/settingsMenu/${isCategoryAB ? 'AB' : 'CD'}.svg`} alt={isCategoryAB ? 'AB' : 'CD'} />
        </div>
        <Link className="menuSettingsText" to="/"> {/* Заменил href на to */}
          <img src="icons/settingsMenu/clearStats.svg" alt="clear stats" />
          <p>Сбросить статистику</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </Link>
        <div className="menuSettingsText">
          <img src="icons/settingsMenu/examPassed.svg" alt="exam passed" />
          <p>Подтверждать ответ</p>
          <label className="switch">
            <input type="checkbox" checked={isConfirmEnabled} onChange={handleToggleConfirm} />
            <span className="slider"></span>
          </label>
        </div>
        <div className="menuSettingsText">
          <img src="icons/settingsMenu/sound.svg" alt="sound" />
          <p>Звуки</p>
          <label className="switch">
            <input type="checkbox" defaultChecked />
            <span className="slider"></span>
          </label>
        </div>
        <div className="menuSettingsText">
          <img src="icons/settingsMenu/sound.svg" alt="sound" />
          <p>Озвучка вопросов</p>
          <label className="switch">
            <input type="checkbox" defaultChecked />
            <span className="slider"></span>
          </label>
        </div>
      </nav>
    </div>
  );
};

export default SettingsPage;
