import React from 'react';
import { Link } from 'react-router-dom';

const ExamResultPassed = ({ correctAnswers, incorrectAnswers, totalQuestions, timeLeft }) => {
  return (
    <div className="main-container">
      <div className="examHeader">
        <Link className="closeButton" to="/">
          <img src="/icons/close.svg" alt="Close" />
        </Link>
        <h3>{timeLeft || 'Результаты теста'}</h3>
        <Link className="closeButton" to="/">
          <img src="/icons/share.svg" alt="Share" />
        </Link>
      </div>

      <div className="passed">
        <img className="passedImg" src="/img/exam_passed/passed.svg" alt="Passed" />
        <div className="mistakesInfo">
          <img src="/img/exam_passed/exam_passed_icons.svg" alt="Mistakes Info" />
          <p>{`${incorrectAnswers || 0} ошибок, ${totalQuestions || 0} вопросов`}</p>
        </div>
      </div>

      <div className="examButtonPad">
        <div className="ButtonsPadLine">
          <Link to="/reset_session" className="confirmAnswerButton">
            <p className="white">Начать заново</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExamResultPassed;
