import React, { useState } from "react";
import signsData from './data/signs.json';
import { Link } from "react-router-dom";

const RoadSignsPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Функция для отображения списка категорий
  const renderCategories = () => (
    <div className="main-container">
      {/* <div className="search-container">
        <input type="text" placeholder="Поиск" className="search-input" />
        <span className="search-icon">
          <img src="icons/search.svg" alt="search" />
        </span>
      </div> */}

      <h2>Знаки</h2>

      <Link className="pointRules" onClick={() => setSelectedCategory("Предупреждающие знаки")}>
        <img src="icons/roadSigns/1.svg" alt="Предупреждающие знаки" />
        <p>1. Предупреждающие знаки</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />

      <Link className="pointRules" onClick={() => setSelectedCategory("Знаки приоритета")}>
        <img src="icons/roadSigns/2.svg" alt="Знаки приоритета" />
        <p>2. Знаки приоритета</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />

      <Link className="pointRules" onClick={() => setSelectedCategory("Запрещающие знаки")}>
        <img src="icons/roadSigns/3.svg" alt="Запрещающие знаки" />
        <p>3. Запрещающие знаки</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />
      
      <Link className="pointRules" onClick={() => setSelectedCategory("Предписывающие знаки")}>
        <img src="icons/roadSigns/4.svg" alt="Предписывающие знакии" />
        <p>4. Предписывающие знаки</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />
      
      <Link className="pointRules" onClick={() => setSelectedCategory("Знаки особых предписаний")}>
        <img src="icons/roadSigns/5.svg" alt="Знаки особых предписаний" />
        <p>5. Знаки особых предписаний</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />
      
      <Link className="pointRules" onClick={() => setSelectedCategory("Информационные знаки")}>
        <img src="icons/roadSigns/6.svg" alt="Информационные знаки" />
        <p>6. Информационные знаки</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />
            
      <Link className="pointRules" onClick={() => setSelectedCategory("Знаки сервиса")}>
        <img src="icons/roadSigns/7.svg" alt="Знаки сервиса" />
        <p>7. Знаки сервиса</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>

      <hr />

      <Link className="pointRules" onClick={() => setSelectedCategory("Знаки дополнительной информации (таблички)")}>
        <img src="icons/roadSigns/8.svg" alt="Знаки доп. информации (таблички)" />
        <p>8. Знаки доп. информации (таблички)</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>
      
      <hr />

      <Link className="pointRules" onClick={() => setSelectedCategory("Горизонтальная разметка")}>
        <img src="icons/roadSigns/road.svg" alt="Горизонтальная разметка" />
        <p>Горизонтальная разметка</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>
      
      <hr />

      <Link className="pointRules" onClick={() => setSelectedCategory("Вертикальная разметка")}>
        <img src="icons/roadSigns/road-vertical.svg" alt="Вертикальная разметка" />
        <p>Вертикальная разметка</p>
        <img src="icons/arrowRight.svg" alt="arrow right" />
      </Link>


    </div>
  );

  const renderSigns = (category) => {
    const signs = signsData[category];
  
    console.log('Выбранная категория:', category);
    console.log('Данные для категории:', signs);
  
    if (!signs || Object.keys(signs).length === 0) {
      return <p>Данные для данной категории отсутствуют.</p>;
    }
  
    return (
      <div className="main-container">
        <div className="header">
          <Link className="closeButton" to="/RoadSigns" onClick={() => setSelectedCategory(null)}>
            <img src="../icons/arrowBack.svg" alt="Back" />
          </Link>
          <h2 className="category-title">{category}</h2>
        </div>
        <div className="signs-list">
          {Object.values(signs).map((sign) => (
            <div key={sign.number} className="sign">
              {sign.image ? (
                <img src={sign.image} alt={sign.title} />
              ) : (
                <p>Изображение отсутствует</p>
              )}
              <p className="sign-number">{sign.number}</p>
              {sign.title && <p className="textStrong">{sign.title}</p>}
              {sign.description && <p>{sign.description}</p>}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  
  


  return selectedCategory ? renderSigns(selectedCategory) : renderCategories();
};



export default RoadSignsPage;