import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Menu from './components/Menu';
import MainPage from './MainPage';
import SettingsPage from './Settings';
import TrafficRulesPage from './TrafficRules';
import RoadSignsPage from './RoadSigns';
import ExamPage from './training/exam';
import ResultsPage from './training/results';
import ExamResult from './training/failed';
import ExamResultPassed from './training/passed';
import ThemsPage from './training/Thems';
import TicketsPage from './training/Tickets';
import PremiumPage from './Premium';

const App = () => {
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(() => {
    const savedValue = localStorage.getItem('isConfirmEnabled');
    return savedValue !== null ? JSON.parse(savedValue) : false;
  });

  const [isCategoryAB, setIsCategoryAB] = useState(() => {
    const savedValue = localStorage.getItem('isCategoryAB');
    return savedValue !== null ? JSON.parse(savedValue) : true; // По умолчанию A_B
  });

  const [user, setUser] = useState(null);

  const handleToggleConfirm = (newValue) => {
    setIsConfirmEnabled(newValue);
    localStorage.setItem('isConfirmEnabled', JSON.stringify(newValue));
  };

  const handleToggleCategory = (newCategory) => {
    setIsCategoryAB(newCategory);
  };

  const location = useLocation();

  const noMenuPaths = ['/Premium', '/training/exam', '/training/Thems', '/training/results', '/training/passed', '/training/failed', '/training/Tickets'];

  const isExamWithTopic = location.pathname.startsWith('/training/exam/topic/');
  const isExamWithTicket = location.pathname.startsWith('/training/exam/') && location.pathname.split('/').length === 4;

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;

      // Установите цвет заголовка и нижнего колонтитула
      if (typeof webApp.setHeaderColor === 'function') {
        webApp.setHeaderColor("#F8F8FA");
      }

      if (typeof webApp.setFooterColor === 'function') {
        webApp.setFooterColor("#F0F1F4");
      }

      // Разворачиваем приложение на весь экран
      if (typeof webApp.expand === 'function') {
        webApp.expand();
      }

      // Получаем информацию о пользователе Telegram
      if (window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
        setUser(telegramUser); // Сохраняем пользователя в стейте
      }
    }
  }, []);

  return (
    <div>
      {!noMenuPaths.includes(location.pathname) && !isExamWithTopic && !isExamWithTicket && <Menu />}

      <Routes>
        <Route path="/" element={<MainPage user={user} />} />
        <Route path="/settings" element={<SettingsPage user={user} onToggleConfirm={handleToggleConfirm} onToggleCategory={handleToggleCategory} />} />
        <Route path="/TrafficRules" element={<TrafficRulesPage />} />
        <Route path="/RoadSigns" element={<RoadSignsPage />} />
        <Route path="/Premium" element={<PremiumPage />} />
        <Route path="/training/Thems" element={<ThemsPage />} />
        <Route path="/training/Tickets" element={<TicketsPage />} />
        <Route path="/training/exam" element={<ExamPage isConfirmEnabled={isConfirmEnabled} />} />
        <Route path="/training/exam/:ticketId" element={<ExamPage isConfirmEnabled={isConfirmEnabled} isCategoryAB={isCategoryAB} />} />
        <Route path="/training/exam/topic/:topicId" element={<ExamPage isConfirmEnabled={isConfirmEnabled} isCategoryAB={isCategoryAB} />} />
        <Route path="/training/results" element={<ResultsPage />} />
        <Route path="/training/passed" element={<ExamResultPassed correctAnswers={19} incorrectAnswers={1} totalQuestions={20} timeLeft="10:20" />} />
        <Route path="/training/failed" element={<ExamResult incorrectAnswers={5} totalQuestions={20} timeLeft="00:10" />} />
      </Routes>
    </div>
  );
};

export default App;
