// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVbCSpFfcX8XIY0aImkDl332oUNz07sSE",
  authDomain: "pdd-mini.firebaseapp.com",
  projectId: "pdd-mini",
  storageBucket: "pdd-mini.appspot.com",
  messagingSenderId: "92147521898",
  appId: "1:92147521898:web:ad3b99c883a3d31928ad13",
  measurementId: "G-KNWZ6K06D2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };