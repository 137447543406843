import React from "react";
import { Link, useNavigate } from "react-router-dom";

const TicketsPage = () => {
  const navigate = useNavigate();

  // Создаем массив с билетами, каждый билет содержит ID, прогресс и общее количество вопросов
  const tickets = Array.from({ length: 40 }, (_, index) => ({
    id: index + 1,
    progress: 0,
    total: 20,
  }));

  // Функция для обработки клика по билету
  const handleTicketClick = (ticketId) => {
    navigate(`/training/exam/${ticketId}`); // Переход на страницу экзамена с номером билета
  };

  return (
    <div className="main-container">
      <div className="header">
        <Link to="/" className="closeButton">
          <img src="../icons/arrowBack.svg" alt="Назад" />
        </Link>
        <h2 className="category-title">Билеты</h2>
      </div>
      <p className="smallText">
        По мере обучения ваш прогресс будет заполняться.
        Ваша цель – все билеты должны быть заполнены!
      </p>
      <div className="ticketsList">
        {tickets.map((ticket) => (
          <div 
            key={ticket.id} 
            className="ticketsItem"
            onClick={() => handleTicketClick(ticket.id)} // Переход по клику на билет
          >
            <p className="themeName">№{ticket.id}</p>
            <p className="themesProgressNumbers">{ticket.progress} из {ticket.total}</p>
            <div className="themesProgressWrapper">
              <div className="themesProgressContainer">
                <div 
                  className="themesProgressBar" 
                  style={{ width: `${(ticket.progress / ticket.total) * 100}%` }} 
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TicketsPage;
