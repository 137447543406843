import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ExamResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { correctAnswers, incorrectAnswers, totalQuestions, timeLeft } = location.state || {};
  
  // Определяем, сдан экзамен или нет
  const isPassed = correctAnswers >= 18 && (incorrectAnswers <= 2) && (timeLeft !== '00:00');

  // Изображения в зависимости от результата
  const resultImage = isPassed 
    ? '/img/exam_passed/passed.svg'
    : '/img/exam_passed/failed.svg';
  
  const mistakesInfoImage = isPassed 
    ? '/img/exam_passed/exam_passed_icons.svg' 
    : '/img/exam_passed/exam_failed_icons.svg';

  // Функция для перезапуска экзамена
  const handleRestartExam = () => {
    navigate('/training/exam');
  };

  return (
    <div className="main-container">
      <div className="examHeader">
        <Link className="closeButton" to="/">
          <img src="/icons/close.svg" alt="Close" />
        </Link>

        <h3>{timeLeft || 'Результаты теста'}</h3>

        <Link className="closeButton" to="/">
          <img src="/icons/share.svg" alt="Share" />
        </Link>
      </div>

      <div className="passed">
        <img className="passedImg" src={resultImage} alt={isPassed ? 'Passed' : 'Failed'} />
        <div className="mistakesInfo">
          <img src={mistakesInfoImage} alt="Mistakes Info" />
          <p>
            {`${incorrectAnswers || 0} ошибок, ${totalQuestions || 0} вопросов`}
          </p>
        </div>
      </div>

      <div className="examButtonPad">
        <div className="ButtonsPadLine">
          {/* Замена Link на кнопку с функцией перезапуска */}
          <button onClick={handleRestartExam} className="confirmAnswerButton">
            <p className="white">Начать заново</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamResult;
