import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Не забудьте добавить Link из react-router-dom

const PremiumPage = () => {
  const [] = useState(false);



  return (
    <div className="premium-page">
      <div className="main-container">
        <div className="image-container">
          <Link to="/Settings" className="closeButton">
            <img src="../icons/arrowBack.svg" alt="Назад" />
          </Link>
          <img src="img/gold-car.jpg" alt="car_image" id="car-image" />
        </div>

        <div className="premium-text-container">
          <h2 className='white'>Подписка Premium</h2>
          <p className='smallText white'>С Premium ваше обучение станет ещё качественее</p>
        </div>

        <div className='priceButtonsContainer'>
          <div className='payButton'>
            <p className='smallText white'>на 1 месяц</p>
            <p className='textStrong white'>1.40 $</p>
          </div>
          <div className='payButton'>
            <p className='smallText white'>навсегда</p>
            <p className='textStrong white'>3.40 $</p>
          </div>
        </div>


        <div className='benefitsList'>
          <div className='advantagePoint'>
            <img src="icons/premium/premiumIcon_01.svg" alt="Без рекламы" />
            <div>
              <p className='textStrong white'>Без рекламы</p>
              <p className='smallText white'>Реклама больше не будет мешать вам готовиться к экзамену</p>
            </div>
          </div>

          <div className='advantagePoint'>
            <img src="icons/premium/premiumIcon_02.svg" alt="Избранное" />
            <div>
              <p className='textStrong white'>Избранное</p>
              <p className='smallText white'>Добавляйте сложные вопросы в Избранное и изучайте их позже</p>
            </div>
          </div>

          <div className='advantagePoint'>
            <img src="icons/premium/premiumIcon_03.svg" alt="Готовность к экзамену" />
            <div>
              <p className='textStrong white'>Готовность к экзамену</p>
              <p className='smallText white'>Узнайте, на сколько процентов вы готовы к настоящему экзамену</p>
            </div>
          </div>

          <div className='advantagePoint'>
            <img src="icons/premium/premiumIcon_04.svg" alt="Озвучка вопросов" />
            <div>
              <p className='textStrong white'>Озвучка вопросов</p>
              <p className='smallText white'>Функция автоматически озвучивает все вопросы и ответы (можно отключить)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPage;