import React from 'react';


const TrafficRulesPage = () => {
    return (
      <div className="main-container">
  
        <h2>ПДД 2025</h2>
  
        <a className="pointRules" href="/">
          <p>1. Общие положения</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>2. Общие обязанности водителей</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>3. Применение специальных сигналов</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>4. Обязанности пешеходов</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>5. Обязанности пассажиров</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>6. Сигналы светофора и регулировщика</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>7. Применение аварийной сигнализации и знака аварийной остановки</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>8. Начало движения, маневрирование</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>9. Расположение транспортных средств на роезжей части</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>10. Скорость движения</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
  
        <hr />
  
        <a className="pointRules" href="/">
          <p>11. Обгон, опережение, встречный разъезд</p>
          <img src="icons/arrowRight.svg" alt="arrow right" />
        </a>
      </div>
    );
  };
  
  export default TrafficRulesPage;